body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.item.translation
{
  height: 100px;
  overflow-y: auto;
}


.score-weight {
  display: inline-block;
  background: #4183c4;
  height: 15px;
}

.icon-action-container {
  margin-left: -15px;
  margin-right: -15px;
}

.icon-action-container .icon {
  margin: 0 5px;
}

